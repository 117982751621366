import React, { useEffect, useState } from 'react';
import logo from './compo/lanterne.png';
import './App.css';
import MapWithPopup from './compo/Map';
import Modal from "./compo/Modal";
import './compo/Map.css';

export default function App() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [modalOpen, setModalOpen] = useState(true);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <div className='App'>
      <div style={{ height: windowSize.innerHeight * 0.12, width: windowSize.innerWidth, display: 'flex', justifyContent: 'left', alignItems: 'center' }} className="rectangle one">
        <img src={logo} alt="logo" height={windowSize.innerHeight * 0.09} width={windowSize.innerHeight * 0.092} />
        <h1 style={{ fontSize: windowSize.innerHeight * 0.046 }} className="Text">Urgence Lanterne</h1>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ height: windowSize.innerHeight * 0.76, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="rectangle two">
          <MapWithPopup />
          <div className='map'>
            {modalOpen && <Modal setOpenModal={setModalOpen} />}
          </div>
        </div>
      </div>
      <div style={{ height: windowSize.innerHeight * 0.12, width: windowSize.innerWidth, display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="rectangle two">
        <h1 style={{ fontSize: ((windowSize.innerHeight * 0.025 / 2) + (windowSize.innerWidth * 0.02 / 2)) }} className="Text"> Votre contacte vous envoie sa localisation grâce à l’application Lanterne pour vous demander assistance car elle/il se sent en danger ou en insécurité</h1>
      </div>
    </div>
  );
}

export function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}