// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map {
    position: fixed;
    z-index: 9999;
}

.button {
    text-align: center;
    background-color: rgb(71, 71, 95);
    color: white;
    padding: 5px 20px;
    cursor: pointer;
    display: block;
    margin: 0 auto;
  }

  .leaflet-control-container .leaflet-routing-container-hide {
    display: none;
  }`, "",{"version":3,"sources":["webpack://./src/compo/Map.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,iCAAiC;IACjC,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,cAAc;IACd,cAAc;EAChB;;EAEA;IACE,aAAa;EACf","sourcesContent":[".map {\n    position: fixed;\n    z-index: 9999;\n}\n\n.button {\n    text-align: center;\n    background-color: rgb(71, 71, 95);\n    color: white;\n    padding: 5px 20px;\n    cursor: pointer;\n    display: block;\n    margin: 0 auto;\n  }\n\n  .leaflet-control-container .leaflet-routing-container-hide {\n    display: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
