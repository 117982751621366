import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

const firebaseConfig = {
    apiKey: "AIzaSyACDLdKS0M-A1eN63VuTTkQf8OyhxrJoko",
    authDomain: "lanrerne.firebaseapp.com",
    databaseURL: "https://lanrerne-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "lanrerne",
    storageBucket: "lanrerne.appspot.com",
    messagingSenderId: "914625024235",
    appId: "1:914625024235:web:e476bf9dad377a87ab2eae",
    measurementId: "G-C6RFMC63L4"
  };

firebase.initializeApp(firebaseConfig);

function lireNoeud(nomNoeud) {
    return new Promise((resolve, reject) => {
        const ref = firebase.database().ref().child('Urgence').child(nomNoeud);
        ref.once('value', (snapshot) => {
            if (snapshot.exists()) {
                resolve(snapshot.val());
            } else {
                reject(new Error(`Le nœud ${nomNoeud} n'existe pas.`));
            }
        }, (error) => {
            reject(error);
        });
    });
}

function lireDonneesFirebase(newKey) {
    return lireNoeud(newKey);
}

export default lireDonneesFirebase;