// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rectangle {
  
  &.one {
   background-color: #2F4E78;
  }
  &.two {
    background-color: #22272E;
  }
 }
 .Text {
  color: #ffffff;
  text-align: center;
}

.leaflet-container {
  width: 100%;
  height: 10;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;EAEE;GACC,yBAAyB;EAC1B;EACA;IACE,yBAAyB;EAC3B;CACD;CACA;EACC,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,UAAU;AACZ","sourcesContent":[".rectangle {\n  \n  &.one {\n   background-color: #2F4E78;\n  }\n  &.two {\n    background-color: #22272E;\n  }\n }\n .Text {\n  color: #ffffff;\n  text-align: center;\n}\n\n.leaflet-container {\n  width: 100%;\n  height: 10;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
